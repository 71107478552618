<template>
    <div>
        <div v-if="kioskEnabled">
            <div v-if="alertStatus" @click="() => { alertStatus = false }">
                <CAlert color="success">{{ $t('saveSuccess') }}</CAlert>
            </div>
            <CRow class=" text-dark">
                <CCol sm="12" md="12">
                    <h3 class="text-left text-dark mb-3">{{ $t('qrMobileConfig') }} ({{ $t('Kiosk') }})</h3>
                    <form>
                        <div class="form-group mt-4">
                            <h5 for="greeting1">{{ $t('shopGreetingTitle') }}</h5>
                            <input type="text" v-model="greetingMessage1" class="form-control" id="greeting1">
                            <small id="emailHelp" class="form-text text-muted">{{ $t('shopGreetingDialog') }}</small>
                        </div>
                    </form>

                    <div class="mb-3">
                        <h5 class="mt-5">{{ $t('addLogoDespt') }}</h5>
                        <small class="text-description text-muted">*{{ $t('addBannerSuggestion') }}</small>
                        <CRow class="mt-2">
                            <CButton id="kioskSettingAddLogoButton" @click="toggleCoverModal()"
                                style="position: relative; width: 180px; height: 90px; background-color: #d8dbe0; color: #878787; font-size: medium; overflow: hidden;"
                                class="img-fluid">
                                <!-- Image -->
                                <img v-if="localCoverImage !== undefined && localCoverImage !== ''"
                                    :src="localCoverImage"
                                    style="width: 100%; height: 100%; object-fit: cover; position: absolute; top: 0; left: 0;">
                                <!-- '+' placeholder -->
                                <div v-else style="display: flex; align-items: center; justify-content: center;">+
                                </div>
                            </CButton>
                        </CRow>
                    </div>

                    <div>
                        <h5 class="mt-5">{{ $t('addBanner') }}</h5>
                        <small class="text-description text-muted">*{{ $t('addBannerSuggestionKiosk') }}</small>
                        <div id="kioskSettingBannerImages" class="mt-2 row flex-row"
                            style="overflow-x: auto; display: flex; flex-wrap: nowrap;">
                            <div :id="'kioskSettingBannerImage' + index - 1" class="m-2"
                                v-for="index in additionImageAmount" :key="'addImage' + index">
                                <CButton :id="'kioskSettingSelectBannerImage' + index - 1"
                                    @click="selectBannerImage(index - 1)"
                                    style="position: relative; width: 270px; height: 90px; background-color: #d8dbe0; color: #878787; font-size: medium; overflow: hidden;"
                                    class="img-fluid">
                                    <CBadge v-if="items.length >= index && items[index - 1] !== null" color="success"
                                        style="z-index: 1000; position: absolute; top: 0px; left: 0px;">
                                        <i class="fi fi-rr-link"></i> {{ items[index - 1].action || '' }}
                                    </CBadge>
                                    <!-- Image -->
                                    <img v-if="localBannerImage[index - 1] !== undefined && localBannerImage[index - 1] !== null"
                                        :src="localBannerImage[index - 1]"
                                        style="width: 100%; height: 100%; object-fit: cover; position: absolute; top: 0; left: 0;">
                                    <!-- '+' placeholder -->
                                    <div v-else style="display: flex; align-items: center; justify-content: center;">+
                                    </div>
                                </CButton>
                            </div>
                        </div>
                    </div>

                    <!-- Screen Saver Section -->
                    <div class="mt-3">
                        <h5 class="mt-5">{{ $t('screenSaverConfig') }}</h5><br>
                        <div class="row">
                            <label class="col col-form-label pl-3" id="kioskSettingImageOption">
                                <input name="screen-saving-options" type="radio" style="accent-color: #29B46B;"
                                    @change="sourceType = 'image'" :checked="sourceType == 'image'" />
                                &ensp; {{ $t('imageKioskScreenSaving') }}
                            </label>
                            <label class="col col-form-label pl-3" id="kioskSettingVideoOption">
                                <input name="screen-saving-options" type="radio" style="accent-color: #29B46B;"
                                    @change="sourceType = 'video'" :checked="sourceType == 'video'" />
                                &ensp; {{ $t('videoKioskScreenSaving') }}
                            </label>
                            <label class="col col-form-label pl-3" id="kioskSettingUnselectOption">
                                <input name="screen-saving-options" type="radio" style="accent-color: #29B46B;"
                                    @change="sourceType = ''" :checked="sourceType == ''" />
                                &ensp; {{ $t('unSelect') }}
                            </label>
                        </div>
                        <br>

                        <template v-if="sourceType == 'image'">
                            <div class="row pl-2">
                                <span class="pt-1 mr-4">{{ $t('startScreenSaverWhen') }}
                                </span>&nbsp;<input id="kioskSettingImageStartTimeInput" v-model="screenSaverTime"
                                    style="width: 100px;" type="number" class="form-control mr-4"> <span class="pt-1 ">
                                    {{ $t('minutes') }}</span>
                            </div>
                            <small class="text-description text-muted">*{{ $t('addBannerSuggestion') }}</small>
                            <div class="mt-2 row flex-row" style="overflow-x: auto; display: flex; flex-wrap: nowrap;">
                                <div :id="'kioskSettingImageItem' + index" class="m-2"
                                    v-for="index in screenSaverImageAmount" :key="'addImage' + index">
                                    <CButton :id="'kioskSettingImageButton' + index"
                                        @click="selectSaverImage(index - 1)"
                                        style="position: relative; width: 95px; height: 180px; background-color: #d8dbe0; color: #878787; font-size: medium; overflow: hidden;"
                                        class="img-fluid">
                                        <!-- Image -->
                                        <img :id="'kioskSettingImagePreview' + index"
                                            v-if="localSaverImage[index - 1] !== undefined && localSaverImage[index - 1] !== null"
                                            :src="localSaverImage[index - 1]"
                                            style="width: 100%; height: 100%; object-fit: cover; position: absolute; top: 0; left: 0;">
                                        <!-- '+' placeholder -->
                                        <div :id="'kioskSettingImagePlaceholder' + index" v-else
                                            style="display: flex; align-items: center; justify-content: center;">+
                                        </div>
                                    </CButton>
                                </div>
                            </div>
                        </template>
                        <template v-if="sourceType == 'video'">
                            <div class="row pl-2">
                                <span class="pt-1 mr-4">{{ $t('startScreenSaverWhen') }}
                                </span>&nbsp;<input id="kioskSettingVideoStartTimeInput" v-model="screenSaverTime"
                                    style="width: 100px;" type="number" class="form-control mr-4"> <span class="pt-1 ">
                                    {{ $t('minutes') }}</span>
                            </div>
                            <div class="mt-2 row flex-row" style="overflow-x: auto; display: flex; flex-wrap: nowrap;">
                                <CButton id="kioskSettingVideoContainer" key="add-video"
                                    @click="uploadVideoModal = true"
                                    :style="{ position: 'relative', width: '180px', height: '90px', margin: '2px', backgroundColor: currentVideo !== '' ? '#3abc98' : '#d8dbe0', color: '#878787', fontSize: 'medium', overflow: 'hidden' }"
                                    class="img-fluid">
                                    <!-- Video -->
                                    <div id="kioskSettingAddVideoButton" v-if="currentVideo !== ''"
                                        style="display: flex; align-items: center; justify-content: center;color: white;">
                                        <i class="fi fi-rr-film"></i>

                                    </div>
                                    <div id="kioskSettingVideoPreview" v-else
                                        style="display: flex; align-items: center; justify-content: center;">
                                        <i class="fi fi-rr-film"></i>
                                    </div>
                                </CButton>
                            </div>
                        </template>

                    </div>
                </CCol>

                <div class="container-fluid mt-5">
                    <div class="d-flex flex-nowrap overflow-auto justify-content-start">
                        <div v-if="sourceType !== ''" class="col-md-6 col-sm-12 flex-shrink-0"
                            id="kioskSettingScreenSaverContainer">
                            <!-- On Screen Saver -->
                            <div>
                                <h5 class="text-center text-dark">{{ $t('onBoardingPagePreview') }}</h5>
                                <div id="kioskSettingScreenSaverSection" v-if="sourceType == 'video'"
                                    class="container-kiosk ">
                                    <div @click="pauseVideo()" style="background-color: black;" class=" kiosk-mocking text-dark d-flex align-items-center
                                        justify-content-center">
                                        <video id="kioskSettingUploadedVideo"
                                            v-if="currentVideoHolder !== '' && videoOption == 'Upload'"
                                            style="width: 100%; height: 100%; " :src="currentVideoHolder"
                                            controls></video>
                                        <LazyYoutube id="kioskSettingLazyYoutube" ref="lazyVideo" :showTitle="false"
                                            v-else-if="currentVideoHolderUrl !== '' && videoOption == 'URL'"
                                            :src="currentVideoHolderUrl" />
                                    </div>
                                </div>
                                <div v-if="sourceType == 'image'" class="container-kiosk ">
                                    <div class="container-kiosk ">
                                        <div style="background-color: white;" class="kiosk-mocking text-dark ">
                                            <div v-if="localSaverImage.length > 1">
                                                <carousel id="kioskSettingImageCarousel" :perPage="1" :autoplay="true"
                                                    :loop="true" :adjustableHeight="true" paginationColor="#29B46B"
                                                    paginationPosition="bottom-overlay">
                                                    <slide v-for="(image, index) in localSaverImage"
                                                        v-if="image !== undefined && image !== null"
                                                        :key="'image' + index"
                                                        :id="'kioskSettingImageCarousel' + index">
                                                        <div>
                                                            <img style="border-radius: 10px;width: 100%;max-height: 500px"
                                                                class="img-fluid" :src="image"
                                                                :id="'kioskSettingImage' + index">
                                                        </div>
                                                    </slide>
                                                </carousel>
                                            </div>
                                            <div v-else>
                                                <img style="border-radius: 10px;max-height: 500px;width: 100%;"
                                                    class="img-fluid" :src="localSaverImage[0]"
                                                    id="kioskSettingSingleImage">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12 flex-shrink-0">
                            <!-- On Boarding -->
                            <div>
                                <h5 class="text-center text-dark">{{ $t('onBoardingPagePreview') }}</h5>
                                <div class="container-kiosk">
                                    <div class="kiosk-mocking text-dark background-image-div">
                                        <div style="color: white;" class="text-center">
                                            <div>
                                                <br>
                                                <h2 class="mt-5">{{ $t('orderHere') }}</h2>
                                                <span id="kioskSettingGreetingMessage">{{ greetingMessage1 }}</span><br>
                                                <img width="278px" class="img-onboarding" :src="localCoverImage"
                                                    id="kioskSettingCoverImage">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12 flex-shrink-0">
                            <!-- On Menulists -->
                            <div>
                                <h5 class="text-center text-dark">{{ $t('menuPagePreview') }}</h5>
                                <div class="container-kiosk">
                                    <div class="kiosk-mocking text-dark background-image-div-kiosk">
                                        <div class="">
                                            <carousel id="kioskSettingMenuListsCarousel" :perPage="1" :autoplay="true"
                                                :loop="true" :adjustableHeight="true" paginationColor="#29B46B"
                                                paginationPosition="bottom-overlay">
                                                <slide v-for="(image, index) in localBannerImage"
                                                    v-if="image !== undefined && image !== null" :key="'image' + index">
                                                    <div :id="'kioskSettingBanner' + index" class="banner-kiosk" :style="{
                                                        'background-image': `url('${image}'), url('${noImg}')`,
                                                        width: '100%',
                                                        'height': '93px',
                                                        'background-size': 'cover',
                                                        'background-position': 'center center',
                                                    }"></div>
                                                </slide>
                                            </carousel>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Add more columns as needed -->
                    </div>
                </div>
                <CButton id="kioskSettingSaveButton" @click="confirmModal = true" :class="saveButtonClass(isDirty)">
                    <i class="fi fi-rr-inbox-out"></i> {{
                        $t('save') }}
                </CButton>
            </CRow>

            <CModal color="success" :show.sync="additionalModal" :title="$t('selectImg')" centered>
                <div class="row">
                    <template v-if="onBanner">
                        <label id="kioskSettingFileInputLabel" v-if="currentImg == ''" class="col-6 pl-3">
                            <input id="kioskSettingFileInput" ref="fileInput" type="file" accept="image/*"
                                @change="handleFileChange($event.target)" />
                        </label>
                        <label id="kioskSettingLinkBannerLabel" v-else class="col-6 pl-3 text-dark">
                            {{ $t('linkBannerTo') }} <span class="text-description"> ({{ $t('optionalChoice') }})</span>
                        </label>
                        <label id="kioskSettingDeleteImageLabel" v-if="currentImg !== ''" class="col-6 text-right pr-4 "
                            style="color: red;" @click="deleteLocalImg()">
                            <i class="fi fi-rr-trash"></i>
                            {{ $t('delete') }}
                        </label>
                        <br>
                    </template>
                    <template v-else>
                        <div v-if="currentImg == ''" class="col-6 pl-3">
                            <input id="kioskSettingNoBannerFileInput" ref="fileInput" type="file" accept="image/*"
                                @change="handleFileChange($event.target)" />
                        </div>
                        <label id="kioskSettingNoBannerDeleteImageLabel" v-if="currentImg !== ''"
                            class="col-12 text-right pr-4" style="color: red;" @click="deleteLocalImg()">
                            <i class="fi fi-rr-trash"></i>
                            {{ $t('delete') }}
                        </label>
                        <br>
                    </template>
                    <CCol>
                        <CRow v-if="currentImg !== '' && currentImg !== null" class="mb-4">
                            <CCol v-if="onBanner" md="8" sm="12" class="text-dark">
                                <CRow>
                                    <label class="col-4 col-form-label pl-3">
                                        <input id="kioskSettingOptionProductInput" name="options" type="radio"
                                            style="accent-color: #29B46B;" @change="updateAction('Product')"
                                            :checked="action == 'Product'" />
                                        &ensp; {{ $t('product') }}
                                    </label>

                                    <label class="col-4 col-form-label">
                                        <input id="kioskSettingOptionURLInput" name="options" type="radio"
                                            style="accent-color: #29B46B;" @change="updateAction('URL')"
                                            :checked="action == 'URL'" />
                                        &ensp; {{ $t('externalLink') }}
                                    </label>
                                    <label class="col-4 col-form-label">
                                        <input id="kioskSettingOptionProductInput" name="options" type="radio"
                                            style="accent-color: #29B46B;" @change="updateAction('')"
                                            :checked="action == ''" />
                                        &ensp; {{ $t('unSelect') }}
                                    </label>
                                </CRow>
                            </CCol>
                            <CCol md="12" sm="12">
                                <input id="kioskSettingProductInput" v-if="action == 'Product' && onBanner" type="text"
                                    class="form-control " @click="() => { selectItemCard = true }"
                                    v-model="bannerDataInputBox" :placeholder="$t('ParticipatingProducts')" readonly>
                                <input id="kioskSettingURLInput" v-else-if="action == 'URL' && onBanner" type="text"
                                    class="form-control" :placeholder="$t('inputURLPlaceholder')"
                                    v-model="bannerDataInputBox">
                                <!-- <input v-else type="text" class="form-control" disabled> -->

                                <!--Select Item Card Popup -->
                                <CCard v-if="selectItemCard == true" style="z-index: 2000;position: absolute;">
                                    <CCardBody>
                                        <CCardTitle style="font-size: medium;">{{ $t('selectProductPlease') }}
                                        </CCardTitle>
                                        <CCardText class="select-item-card">
                                            <div id="kioskSettingProductItem" v-for="product in products"
                                                :key="product.name" class="mt-4 row"
                                                @click="updateBannerData(product.productSKU)">
                                                <div id="kioskSettingProductImage" class="col-2"><img class="img-fluid"
                                                        style="border-radius: 5px;" :src="product.remoteImagePath">
                                                </div>
                                                <div id="kioskSettingProductName" class="text-dark pt-2 col-10"> {{
                                                    product.name }}
                                                </div>
                                            </div>
                                        </CCardText>
                                        <CButton id="kioskSettingCancelButton" @click="selectItemCard = false">{{
                                            $t('cancel') }}
                                        </CButton>
                                    </CCardBody>
                                </CCard>

                            </CCol>
                        </CRow>

                    </CCol>


                </div>
                <div class="text-center">
                    <img id="kioskSettingImagePreview" v-if="currentImg !== ''" style="width:100%;" :src="currentImg"
                        class="image-fluid">
                </div>

                <template #footer-wrapper>
                    <CRow class="p-4">
                        <CCol>
                            <CButton id="kioskSettingSaveButton" block color="success"
                                v-if="loadingSaveimg === true && currentImg !== ''" v-on:click="saveBannerLocal">
                                {{ $t('save') }}
                            </CButton>
                            <CButton id="kioskSettingSaveButtonDisabled" block color="success" v-else disabled>
                                {{ $t('save') }}
                            </CButton>
                        </CCol>
                        <CCol>
                            <CButton id="kioskSettingCancelButton" block color="light" @click="additionalModal = false">
                                {{ $t('cancel') }}
                            </CButton>
                        </CCol>
                    </CRow>
                </template>
            </CModal>

            <CModal id="kioskSettingCoverImageModal" color="success" :show.sync="coverImageModal"
                :title="$t('selectImg')" centered>
                <div class="row">
                    <label v-if="currentImg == ''" class="col-6 pl-3">
                        <input id="kioskSettingFileInput" ref="fileInput" type="file" accept="image/*"
                            @change="handleFileChange($event.target)" />
                    </label>
                    <label id="kioskSettingNoFileInputLabel" v-else class="col-6 pl-3">
                    </label>

                    <label id="kioskSettingDeleteImageLabel" v-if="currentImg !== '' && currentImg !== null"
                        class="col-6 text-right pr-4" style="color: red;" @click="deleteLocalCoverImg()">
                        <i class="fi fi-rr-trash"></i>
                        {{ $t('deleteImg') }}
                    </label>
                    <br>

                </div>
                <div class="text-center">
                    <img id="kioskSettingImagePreview" v-if="currentImg !== ''" style="width:100%;" :src="currentImg"
                        class="image-fluid">
                </div>

                <template #footer-wrapper>
                    <CRow class="p-4">
                        <CCol>
                            <CButton id="kioskSettingSaveButton" block color="success"
                                v-if="loadingSaveimg === true && currentImg !== ''" v-on:click="saveCoverImageLocal">
                                {{ $t('save') }}
                            </CButton>
                            <CButton id="kioskSettingSaveButtonDisabled" block color="success" v-else disabled>
                                {{ $t('save') }}
                            </CButton>
                        </CCol>
                        <CCol>
                            <CButton id="kioskSettingCancelButton" block color="light" @click="coverImageModal = false">
                                {{ $t('cancel') }}
                            </CButton>
                        </CCol>
                    </CRow>
                </template>
            </CModal>
            <CModal id="kioskSettingUploadVideoModal" color="success" :show.sync="uploadVideoModal"
                :title="$t('uploadVideo')" centered>
                <div class="row justify-content-center" v-if="videoModalStage == 0">

                    <div class="col-md-6 col-sm-12 text-center  ">
                        <div id="kioskSettingUploadOption" class="pt-5 pb-5" @click="videoOptionSelect(1)"
                            style="border-radius: 10px; border-style: dashed; border-color: #cfcfcf; border-width: 5px;">
                            <i class="fi fi-rr-upload" style="font-size: xx-large;"></i> <br>
                            <p class="mt-2">{{ $t('uploadVideo') }}</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 text-center  ">
                        <div id="kioskSettingYoutubeOption" class="pt-5 pb-5" @click="videoModalStage = 2"
                            style="border-radius: 10px; border-style: dashed; border-color: #cfcfcf; border-width: 5px;">
                            <i class="fi fi-rr-play" style="font-size: xx-large;"></i><br>
                            <p class="mt-2">Youtube URL</p>
                        </div>
                    </div>
                </div>

                <CRow v-if="videoModalStage == 1">
                    <label id="kioskSettingDeleteVideoLabel" v-if="currentVideoHolder !== ''"
                        class="col text-right pr-4 mb-4" style="color:red;" @click="deleteVideo()">
                        <i class="fi fi-rr-trash"></i>{{ $t('deleteVideo') }}
                    </label>
                    <video id="kioskSettingVideoPlayer" v-if="currentVideoHolder !== ''"
                        style="width: 100%; height: 100%; " :src="currentVideoHolder" controls></video>

                    <label class="col-12 ">
                        <div id="kioskSettingUploadVideoPlaceholder" v-if="currentVideoHolder == ''"
                            class="text-center pt-5 pb-3"
                            style="border-radius: 10px; border-style: dashed; border-color: #cfcfcf; border-width: 5px;">
                            <i class="fi fi-rr-upload" style="font-size:xx-large;"></i> <br>
                            <p class="mt-2">{{ $t('uploadVideo') }}</p>
                            <p class="text-danger">*{{ $t('videoSizeNotTooBig') }}</p>
                            <input id="kioskSettingVideoInput" style="visibility: hidden;" ref="video" type="file"
                                accept="video/*" @change="handleVideoFileChange($event.target)" />
                        </div>
                    </label>

                </CRow>
                <CRow v-if="videoModalStage == 2">
                    <label id="kioskSettingDeleteYoutubeLabel" v-if="currentVideoHolderUrl !== ''"
                        class="col text-right pr-4 mb-4" style="color:red;" @click="deleteVideo()"> <i
                            class="fi fi-rr-trash"></i> {{ $t('deleteVideo') }}
                    </label>
                    <LazyYoutube id="kioskSettingYoutubePlayer" v-if="currentVideoHolderUrl !== '' && uploadVideoModal"
                        :src="currentVideoHolderUrl" />
                    <label class="col-12 mt-3">{{ $t('pasteURL') }} <small style="color: rgb(180, 180, 180)">*Ex.
                            https://youtu.be/sRxrwjOtIag?si=3yfcN0XRXnsJlQxM</small> </label>
                    <CCol md="12" sm="12">
                        <CInput id="kioskSettingYoutubeInput" v-model="currentVideoHolderUrl"></CInput>
                    </CCol>

                </CRow>


                <template #footer-wrapper>
                    <div></div>
                    <CRow class="p-3" v-if="videoModalStage !== 0">
                        <CCol>
                            <CButton id="kioskSettingSaveButton" block color="success" v-if="loadingSaveimg === true"
                                v-on:click="saveVideo()">
                                {{ $t('save') }}
                            </CButton>
                            <CButton id="kioskSettingSaveButtonDisabled" block color="success"
                                v-else-if="loadingSaveimg === false" disabled>
                                <CSpinner color="white" size="sm" /> {{ $t('save') }}
                            </CButton>
                        </CCol>
                        <CCol>
                            <CButton id="kioskSettingCancelButton" block color="light" @click="videoModalStage = 0">
                                {{ $t('cancel') }}
                            </CButton>
                        </CCol>
                    </CRow>
                </template>
            </CModal>
            <modal id="kioskSettingConfirmModal" color="success" :show.sync="confirmModal"
                :title="$t('confirmSaveKioskConfig')" v-on:isSave="savedata" centered :loading="loading">
                <div class="text-center">
                    <br />
                    <h4 class="text-center text-dark">{{ $t('confirmSaveKioskConfigDialog') }}</h4>
                    <br />
                    <template v-if="!loading">
                        <CProgress id="kioskSettingConfirmModalProgress" class="mb-3" color="success" variant="striped"
                            animated :value="progressValue" />
                        <span>{{ progressText }}</span>
                    </template>

                </div>
            </modal>
            <Logout id="kioskSettingLogoutComponent" :isVisible="loginAgainModal"
                @update:isVisible="loginAgainModal = $event" />
        </div>
        <div v-else>
            <div class="text-center mt-2" style="font-weight: bold; font-size:xx-large">
                Self Service Kiosk
                <!-- {{ $t('selfOrder') }} <span class="badge badge-success ml-2 ">{{ $t('freeTrial') }}</span> -->
            </div>
            <br />
            <div style="display: flex; justify-content: center; align-items: center;">
                <img class="img-fluid mb-3" src="\img\silomKioskAds.jpg" style="width: 55%; " />
            </div>
            <div class="text-center m-5 " style="font-weight:bold; color: #3abc98;">
                <div>
                    <h3>
                        {{ $t('kioskAds') }}
                    </h3>
                </div>
                <br />
                <a id="kioskSettingAddLine" href="https://line.me/R/ti/p/@wdi0271v">
                    <img src="https://scdn.line-apps.com/n/line_add_friends/btn/th.png" alt="เพิ่มเพื่อน" height="36"
                        border="0" /></a>
            </div>
        </div>
    </div>
</template>

<script>
import Logout from '../../containers/Logout.vue';
import { mapGetters } from 'vuex'
import util from '@/util/util'
import permis from '@/util/permission'
import { Carousel, Slide } from 'vue-carousel';
import pos from '@/services/local';
import VueCropper from 'vue-cropperjs'
import mime from '@/util/mime'
import uploadImage from '@/util/uploadImage'
import { LazyYoutube } from "vue-lazytube"
import firebase from 'firebase'



export default {
    components: {
        Logout,
        Carousel,
        Slide,
        VueCropper,
        LazyYoutube
    },
    data() {
        return {
            data: [],
            loading: true,
            isSelfOrderEnabled: '',
            greetingMessage1: '',
            products: [],
            additionImageAmount: 5,
            screenSaverImageAmount: 10,
            localBannerImage: new Array(5),
            localSaverImage: new Array(10),
            currentButton: 0,
            currentImg: '',
            additionalModal: false,
            loadingSaveimg: true,
            resizedImage: null,
            items: [],
            action: '',
            bannerData: '',
            option: ['object', 'link'],
            selectItemCard: false,
            bannerDataInputBox: '',
            orderShopCollection: [],
            confirmModal: false,
            alertStatus: false,
            coverImageModal: false,
            localCoverImage: '',
            imagePathDeleted: [],
            isKioskEnabled: false,
            sourceType: '',
            onBanner: true,
            saver: [],
            uploadVideoModal: false,
            videoOption: '',
            currentVideoHolder: '',
            currentVideoHolderUrl: '',
            currentVideo: '',
            lazyTubePlay: false,
            screenSaverTime: 0,
            videoModalStage: 0,
            progressValue: 0,
            progressText: '',
            loginAgainModal: false,
            isDirty: false,
        }


    },
    computed: {
        ...mapGetters(['shops', 'users', 'isOwner', 'shopsetting', 'permissions']),
        isPermission() {
            this.getProduct()
            this.getOrderShopSetting()
            return permis.findPermissionRead('selfOrder', this.$route.path)
        },
        isEditData() {
            return permis.findPermissionEdit('selfOrder', this.$route.path)
        },
        uid() {
            return `${localStorage.getItem('uid')}`
        },
        selfOrderEnabled: {
            get() {
                const shop = this.shop || {}
                const delivery = shop.delivery || {}
                if (delivery.isSelfOrderEnabled != undefined) {
                    this.isSelfOrderEnabled = delivery.isSelfOrderEnabled
                } else {
                    this.isSelfOrderEnabled = false
                }
            },
            set(value) {
                this.isSelfOrderEnabled = value
            },
        },
        shopObjectId: {
            get() {
                return this.$store.getters.shopObjectId
            },
        },
        shop() {
            return this.shops.find((i) => i.objectId === this.shopObjectId)
        },
        noImg() {
            return process.env.VUE_APP_NOIMAGE
        },
        datashop: {
            get() {
                return this.$store.getters.shopsetting
            },
            set(newValue) {
                return this.$store.dispatch('setShopSetting', newValue)
            },
        },
        recommendedProducts() {
            // Filter products to get only the recommended ones
            return this.products.filter(product => product.ProductSKU.isRecommend === true);
        },
        kioskEnabled: {
            get() {
                const shop = this.shop || {};
                const delivery = shop.delivery || {};
                const isKioskEnabled = delivery.isKioskEnabled || false;

                const kioskMenu = this.permissions.setting.menu.find(menu => menu.label === 'kiosk');
                const kioskLock = kioskMenu.lock


                if (isKioskEnabled === true && kioskLock === false) {
                    return true;
                } else {
                    return false;
                }
            },
            set(value) {
                this.isKioskEnabled = value;
            }
        }
    },
    created() {
        this.getProduct()
        this.getOrderShopSetting()
    },

    methods: {
        ...util,
        logout() {
            this.loadingButton = false
            firebase
                .auth()
                .signOut()
                .then(() => {
                    // Sign-out successful.
                    localStorage.clear()
                    window.location.href = '/login'
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        videoOptionSelect(i) {
            this.videoModalStage = i
            if (i == 1) {
                if (typeof this.orderShopCollection.kiosk.screenSaver.videoPaths === 'string' && this.orderShopCollection.kiosk.screenSaver.videoPaths.startsWith("https://firebasestorage")) {
                    this.currentVideoHolder = this.orderShopCollection.kiosk.screenSaver.videoPaths;
                    this.currentVideo = this.orderShopCollection.kiosk.screenSaver.videoPaths;
                } else {
                    this.currentVideoHolder = '';
                    this.currentVideo = '';
                }


            }
        },
        pauseVideo() {
            this.lazyTubePlay = !this.lazyTubePlay
            if (this.lazyTubePlay) {
                this.$refs["lazyVideo"]['pauseVideo']()
            } else {
                this.$refs["lazyVideo"]['playVideo']()
            }

        },
        async uploadVideoToFirebase() {
            this.progressValue = 80
            const ownerUID = `${localStorage.getItem('uid')}`;
            if (this.videoOption == 'Upload' && !this.currentVideoHolder.startsWith("https://firebasestorage") && this.currentVideoHolder !== '' && this.sourceType == 'video') {
                this.onUploadText = this.$i18n.t('videoUploading')
                this.progressValue = 80
                let videoPathsUrl = await this.blobToDataURL(this.currentVideoHolder)
                let upload = await uploadImage.onUpload2('resources/' + ownerUID + '/resources/saver/kiosk/videoSaver', videoPathsUrl)
                this.sourceType = 'video'
                this.currentVideo = upload
                if (!this.currentVideo.startsWith("https://firebasestorage")) {
                    this.loginAgainModal = true
                } else {
                    this.loginAgainModal = false
                }
            }
        },
        async blobToDataURL(blobUrl) {
            const response = await fetch(blobUrl);
            const blob = await response.blob();

            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => {
                    resolve(reader.result);
                };
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        },
        saveVideo() {
            if (this.videoModalStage == 2) {
                this.currentVideo = this.currentVideoHolderUrl
                this.currentVideoHolder = this.currentVideoHolderUrl
                this.videoOption = 'URL'
            } else {
                this.currentVideo = this.currentVideoHolder
                this.videoOption = 'Upload'

            }
            this.uploadVideoModal = false
        },
        handleVideoFileChange: function (event) {
            this.currentVideoHolder = '';
            let file = event.files[0];

            if (file.size <= 20971520) {
                this.$nextTick(() => {
                    this.currentVideoHolder = window.URL.createObjectURL(file)
                })
            } else {
                event.target = '';
                alert(this.$i18n.t('bigVideoAlert'))
            }
        },
        deleteVideo() {
            this.imagePathDeleted.push(this.currentVideo)
            this.videoOption = ''
            this.currentVideo = ''
            this.currentVideoHolder = ''
        },
        showMessageStatus() {
            this.alertStatus = true;
            setTimeout(() => {
                this.alertStatus = false;
            }, 5000);

        },
        updateBannerData(productSKU) {
            this.bannerDataInputBox = productSKU.SKUName
            this.bannerData = productSKU.objectId
            this.selectItemCard = false
        },
        updateAction(action) {
            this.bannerDataInputBox = ''
            this.action = action
            this.selectItemCard = false

        },
        addObject(imagePath, action, data) {
            this.items.push({ imagePath, action, data });
        },
        setItemAtIndex(index, imagePath, action, data) {
            this.$set(this.items, index, { imagePath, action, data });
        },
        setImagePathAtIndex(index, newPath) {
            this.$set(this.items[index], 'imagePath', newPath);
        },
        getCurrentImage() {
            return this.currentImg
        },
        toggleCoverModal() {

            if (this.localCoverImage == '') {
                this.currentImg = ''
            } else {
                this.currentImg = this.localCoverImage
            }
            this.coverImageModal = true
        },
        async saveCoverImageLocal() {
            this.loadingSaveimg = false
            let resizedImage = await this.resizeImage(this.currentImg)
            this.localCoverImage = resizedImage
            this.currentImg = ''
            this.coverImageModal = false
            this.loadingSaveimg = true
            // this.getImageURL2()
        },
        async saveBannerLocal() {
            this.loadingSaveimg = false
            let resizedImage

            if (this.onBanner) {
                if (this.items[this.currentButton] == undefined && this.items[this.currentButton] !== null) {
                    resizedImage = await this.resizeImage(this.currentImg)
                    if (this.action == 'Product') {
                        this.addObject(resizedImage, this.action, this.bannerData)
                    } else {
                        this.addObject(resizedImage, this.action, this.bannerDataInputBox)
                    }
                    this.localBannerImage[this.currentButton] = resizedImage
                } else {
                    resizedImage = this.currentImg
                    let data
                    if (this.action == 'Product') {
                        data = this.bannerData
                    } else {
                        data = this.bannerDataInputBox
                    }
                    this.localBannerImage[this.currentButton] = resizedImage
                    this.setItemAtIndex(this.currentButton, resizedImage, this.action, data)
                }
            } else {
                resizedImage = await this.resizeImage(this.currentImg)
                this.localSaverImage[this.currentButton] = resizedImage
                // this.$set(this.saver, this.currentButton, resizedImage)
            }

            this.action = ''
            this.bannerData = ''
            this.currentImg = ''
            this.additionalModal = false
            this.loadingSaveimg = true
        },
        async getImageURL() {
            this.progressValue = 50
            if (!this.localCoverImage.startsWith("https://firebasestorage")) {
                let ext = mime.getExtension(this.localCoverImage);
                if (ext == null) {
                    this.isload = false;
                } else {
                    const ownerUID = this.uid
                    const directoryPath = 'resources/' + ownerUID + '/resources/coverImageKiosk'
                    let imagePath = await uploadImage.onUpload2(directoryPath, this.localCoverImage)
                    this.localCoverImage = imagePath
                    if (!this.localCoverImage.startsWith("https://firebasestorage")) {
                        this.loginAgainModal = true
                    } else {
                        this.loginAgainModal = false
                    }
                }
            }
            for (let i = 0; i < this.items.length; i++) {
                if (this.localBannerImage[i] !== null && !this.localBannerImage[i].startsWith("https://firebasestorage")) {
                    let ext = mime.getExtension(this.localBannerImage[i]);
                    if (ext == null) {
                        this.isload = false;
                    } else {
                        const imageName = util.generateFilename('.' + ext)
                        const ownerUID = this.uid
                        const directoryPath = 'resources/' + ownerUID + '/resources/banner/kiosk/' + imageName
                        let imagePath = await uploadImage.onUpload2(directoryPath, this.localBannerImage[i])
                        if (!imagePath.startsWith("https://firebasestorage")) {
                            this.loginAgainModal = true
                        } else {
                            this.loginAgainModal = false
                            this.setImagePathAtIndex(i, imagePath);
                        }
                    }
                }
            }
        },
        async getImageURLSaver() {
            this.progressValue = 25
            this.progressText = this.$i18n.t('imageUploading')
            for (let i = 0; i < this.localSaverImage.length; i++) {
                if (this.localSaverImage[i]) {
                    if (this.localSaverImage[i] !== null && !this.localSaverImage[i].startsWith("https://firebasestorage")) {
                        let ext = mime.getExtension(this.localSaverImage[i]);
                        if (ext == null) {
                            this.isload = false;
                        } else {
                            const imageName = util.generateFilename('.' + ext)
                            const ownerUID = this.uid
                            const directoryPath = 'resources/' + ownerUID + '/resources/saver/kiosk/' + imageName
                            let imagePath = await uploadImage.onUpload2(directoryPath, this.localSaverImage[i])
                            if (!imagePath.startsWith("https://firebasestorage")) {
                                this.loginAgainModal = true
                            } else {
                                this.loginAgainModal = false
                                this.saver.push(imagePath)
                            }
                        }
                    } else if (this.localSaverImage[i].startsWith("https://firebasestorage")) {
                        this.saver.push(this.localSaverImage[i])
                    }
                }
            }
        },


        handleFileChange: function (event) {
            //this.isUpload = true
            this.currentImg = '';
            let file = event.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = (e) => {
                this.currentImg = e.target.result;
                // this.isUpload = false
            };
        },
        async deleteImageFromFirebase() {
            this.progressValue = 25
            if (this.currentVideo.startsWith('https://www.youtube.com')) {
                this.imagePathDeleted.push(this.orderShopCollection.kiosk.screenSaver.videoPaths)
            }

            for (const element of this.imagePathDeleted) {
                try {
                    await uploadImage.deleteImage(this.uid, this.shopObjectId, element)
                } catch (error) {
                    console.error('Error deleting image:', error)
                }
            }
            this.imagePathDeleted = []
        },
        async deleteLocalCoverImg() {
            if (this.localCoverImage !== undefined && this.localCoverImage !== '') {
                if (this.localCoverImage.startsWith("https://firebasestorage")) {
                    this.imagePathDeleted.push(this.localCoverImage)
                }

            }
            this.localCoverImage = ''
            this.currentImg = ''
            this.coverImageModal = false
        },
        async deleteLocalImg() {
            if (this.onBanner) {
                if (this.localBannerImage[this.currentButton] !== undefined && this.localBannerImage[this.currentButton] !== null) {
                    if (this.localBannerImage[this.currentButton].startsWith("https://firebasestorage")) {
                        this.imagePathDeleted.push(this.localBannerImage[this.currentButton])
                    }

                }
                this.action = ''
                this.bannerDataInputBox = ''
                this.localBannerImage[this.currentButton] = null
                this.items[this.currentButton] = null
                this.currentImg = ''
                this.localBannerImage = this.localBannerImage.filter(localBannerImage => localBannerImage !== null);
                this.items = this.items.filter(item => item !== null);
                this.currentButton = null
                this.additionalModal = false
            } else {
                if (this.localSaverImage[this.currentButton] !== undefined && this.localSaverImage[this.currentButton] !== null) {
                    if (this.localSaverImage[this.currentButton].startsWith("https://firebasestorage")) {
                        this.imagePathDeleted.push(this.localSaverImage[this.currentButton])
                    }

                }
                this.localSaverImage[this.currentButton] = null
                this.currentImg = ''
                this.localSaverImage = this.localSaverImage.filter(localSaverImage => localSaverImage !== null);
                this.currentButton = null
                this.additionalModal = false
            }
        },
        selectBannerImage(index) {
            this.currentButton = index
            if (this.localBannerImage[index] !== undefined && this.localBannerImage[index] !== null) {
                this.currentImg = this.localBannerImage[index] || ''

                if (this.items[index].action == 'Product') {
                    this.bannerDataInputBox = this.getSkuName(this.items[index].data)
                } else {
                    this.bannerDataInputBox = this.items[index].data || ''
                }

                this.bannerData = this.items[index].data || ''
                this.action = this.items[index].action || ''
            } else {
                this.currentImg = ''
                this.bannerDataInputBox = ''
                this.bannerData = ''
                this.action = ''
            }
            this.selectItemCard = false
            this.onBanner = true
            this.additionalModal = true
        },
        selectSaverImage(index) {
            this.currentButton = index
            if (this.localSaverImage[index] !== undefined && this.localSaverImage[index] !== null) {
                this.currentImg = this.localSaverImage[index] || ''
            } else {
                this.currentImg = ''
            }
            this.selectItemCard = false
            this.onBanner = false
            this.additionalModal = true
        },

        getSkuName(itemObjectId) {
            const product = this.products.find(product => product.productSKU.objectId === itemObjectId);
            if (product) {
                return product.ProductSKU.SKUName
            }
        },

        async resizeImage(base64Str, maxWidth = 1280, maxHeight = 640) {
            return new Promise((resolve) => {
                let img = new Image()
                img.src = base64Str
                img.onload = () => {
                    let canvas = document.createElement('canvas')
                    const MAX_WIDTH = maxWidth
                    const MAX_HEIGHT = maxHeight
                    let width = img.width
                    let height = img.height

                    if (width > height) {
                        if (width > MAX_WIDTH) {
                            height *= MAX_WIDTH / width
                            width = MAX_WIDTH
                        }
                    } else {
                        if (height > MAX_HEIGHT) {
                            width *= MAX_HEIGHT / height
                            height = MAX_HEIGHT
                        }
                    }
                    canvas.width = width
                    canvas.height = height
                    let ctx = canvas.getContext('2d')
                    ctx.drawImage(img, 0, 0, width, height)
                    resolve(canvas.toDataURL())

                }
            })
        },

        getProduct(page = 1) {
            const uid = this.uid
            const categoryObjectId = ''
            let params = {
                shopObjectId: this.shopObjectId,
                page: page,
                limit: 50,
                searchVal: '',
            }
            if (categoryObjectId !== '') {
                params['categoryObjectId'] = categoryObjectId
            }

            pos({
                method: 'get',
                url: '/api/v1.0/' + uid + '/Shop/getproduct/view',
                params: params,
            }).then((res) => {
                this.products = res.data.data



            })
        },

        getOrderShopSetting() {
            const uid = this.uid
            let params = {
                shopObjectId: this.shopObjectId,
            }

            pos({
                method: 'get',
                url: '/api/v2.0/' + uid + '/OrderShopSetting',
                params: params,
                headers: { shopObjectId: this.shopObjectId }
            }).then((res) => {
                this.orderShopCollection = res.data.data[0] || null
                if (this.orderShopCollection?.kiosk !== undefined) {
                    this.localCoverImage = this.orderShopCollection.kiosk.remoteCoverImagePath || ''
                    this.greetingMessage1 = this.orderShopCollection.kiosk.greetingMessage || ''
                    if (this.orderShopCollection.kiosk.banner !== null) {
                        this.items = this.orderShopCollection.kiosk.banners
                        this.items.forEach((data, index) => {
                            this.localBannerImage[index] = data.imagePath;
                        });
                    }
                    if (this.orderShopCollection.kiosk.screenSaver !== null) {
                        this.localSaverImage = this.orderShopCollection.kiosk.screenSaver.imagePaths
                        this.sourceType = this.orderShopCollection.kiosk.screenSaver.sourceType
                        this.currentVideoHolder = this.orderShopCollection.kiosk?.screenSaver?.videoPaths?.[0]
                        this.screenSaverTime = this.orderShopCollection.kiosk.screenSaver.screenSaverTime
                        this.currentVideo = this.currentVideoHolder || ''
                        if (this.currentVideo.startsWith("https://firebasestorage")) {
                            this.videoOption = 'Upload'
                            this.videoModalStage = 1
                        } else if (this.currentVideo !== '') {
                            this.videoOption = 'URL'
                            this.currentVideoHolderUrl = this.currentVideo
                            this.videoModalStage = 2
                        }
                    }
                }
            })

        },

        async savedata() {
            this.loading = false
            await this.deleteImageFromFirebase()
            this.saver = this.saver.filter(element => element !== null)
            await this.getImageURLSaver()
            this.items = this.items.filter(item => item !== null)
            await this.getImageURL()
            await this.uploadVideoToFirebase()


            let videoURL = []
            videoURL.push(this.currentVideo)


            const shopObjectId = this.shopObjectId
            const uid = `${localStorage.getItem('uid')}`

            let data = {
                "updated_at": new Date(),
                "created_by_id": uid,
                "enabled": true,

                "shop": {
                    "objectId": shopObjectId,
                    "id": this.datashop.id,
                    "branchName": this.datashop.branchName
                },
                "kiosk": {
                    "banners": this.items,
                    "screenSaver": {
                        sourceType: this.sourceType,
                        imagePaths: this.saver,
                        videoPaths: videoURL,
                        screenSaverTime: parseInt(this.screenSaverTime)
                    },
                    "remoteCoverImagePath": this.localCoverImage,
                    "greetingMessage": this.greetingMessage1,
                },
                "shopId": this.datashop.id,
                "uid": uid,
            }

            if (this.orderShopCollection == null) {
                pos({
                    method: 'post',
                    url: '/api/v2.0/' + this.uid + '/OrderShopSetting',
                    data: data,
                })
                    .then((res) => {
                        this.progressValue = 100
                        this.currentVideoHolderUrl = ''
                        this.loading = true
                        this.confirmModal = false
                        this.showMessageStatus()
                        this.getProduct()
                        this.getOrderShopSetting()
                        window.scrollTo(0, 0)
                        this.saver = []
                    })
            } else {
                pos({
                    method: 'patch',
                    url: '/api/v2.0/' + this.uid + '/OrderShopSetting/' + this.orderShopCollection.objectId,
                    data: data,
                })
                    .then((res) => {
                        this.progressValue = 100
                        this.currentVideoHolderUrl = ''
                        this.loading = true
                        this.confirmModal = false
                        this.showMessageStatus()
                        this.getProduct()
                        this.getOrderShopSetting()
                        window.scrollTo(0, 0)
                        this.saver = []
                    })
            }
        },
        saveButtonClass(isDirty) {
            if (isDirty == false) {
                return 'floating-save-btn btn btn-success'
            } else {
                return 'floating-save-btn-dirty btn btn-success'
            }
        },

    },
}
</script>

<style src="../../assets/styles/inventory.css" scoped></style>

<style src="../../assets/styles/multiselect.css" scoped></style>

<style scoped>
.square-box {
    position: relative;
    width: 20%;
    overflow: hidden;
    border-radius: 3px;
}

/* .VueCarousel-wrapper {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
} */


.square-box:before {
    content: '';
    display: block;
    padding-top: 100%;
}

.kiosk-mocking {
    border-radius: 10px;
    width: 280px;
    height: 500px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(205, 205, 205);
    margin: 0 auto;
    /* Aligns the div horizontally center */
}

.banner-kiosk {
    height: 93px;
    border-radius: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.container-kiosk {
    display: flex;
    justify-content: center;
    align-items: center;
}

.background-image-div {
    background-image: url('/img/onboardingKiosk.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.background-image-div-kiosk {
    background-image: url('/img/menulistsKiosk.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}



.toggles {
    position: relative;
    display: inline-block;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 8px;
    border-radius: 5px;
    width: 50px;
    height: 30px;
    background-color: #e5f1e5;
    color: #2d8c28;
    font-size: small;
}

.callStaff {
    display: inline-block;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 8px;
    border-radius: 5px;
    width: 50px;
    height: 30px;
    background-color: #29B46B;
    color: #ffffff;
    font-size: small;
}


.image-input {
    background-color: rgb(230, 230, 230);
    width: 100%;
    height: 200px;
    border-radius: 10px;
}

.select-item-card {
    height: 300px;
    overflow-x: scroll;
}

.img-onboarding {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

.container-fluid {
    padding: 0;
}

.d-flex.flex-nowrap {
    flex-wrap: nowrap;
}

.col-md-6,
.col-sm-12 {
    min-width: 50%;
}

.vlt-preview::before {
    visibility: hidden
}

.vlt-preview::after {
    visibility: visible
}

.floating-save-btn {
    position: fixed;
    bottom: 25px;
    right: 100px;
    z-index: 1999;
    padding: 12px 54px;
    font-size: 16px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    background: #3abc98;
    color: white;
    border: none;
    cursor: pointer;
}

.floating-save-btn-dirty {
    position: fixed;
    bottom: 25px;
    right: 100px;
    z-index: 1999;
    padding: 12px 54px;
    font-size: 16px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    background: #3abc98;
    color: white;
    border: none;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    animation: pulseEffect 1.5s infinite;
}

/* Center the button on smaller screens (mobile) */
@media screen and (max-width: 768px) {
    .floating-save-btn {
        right: 50%;
        transform: translateX(50%);
    }

    .floating-save-btn-dirty {
        right: 50%;
        transform: translateX(50%);
    }
}

/* Pulsating animation */
@keyframes pulseEffect {
    0% {
        box-shadow: 0 0 0 0 rgba(58, 188, 152, 0.5);
    }

    70% {
        box-shadow: 0 0 0 15px rgba(58, 188, 152, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(58, 188, 152, 0);
    }
}
</style>
